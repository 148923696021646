import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import axios from "axios";
import { Link } from "react-router-dom";

const UpiIdFraud = () => {
  const [upi, setUpi] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  let [limit, setLimit] = useState(10);
  const [searchType, setSearchType] = useState("Upifraud");
  const [user, setUser] = useState(null);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);
  };
  const setpageLimit = (event) => {
    let key = event.target.value;
    setLimit(key);
  };

  const access_token = localStorage.getItem("mjLudoTokenDash");
  const headers = {
    Authorization: `Bearer ${access_token}`,
  };

  const userFraudListHanlder = async () => {
    await axios
      .get(
        baseUrl +
          `user/upi-list?page=${pageNumber}&_limit=${limit}&type=${searchType}`,
        { headers }
      )
      .then((res) => {
        setUser(res?.data?.data);
        setNumberOfPages(res.data.total);
        // $('table').dataTable();
      })
      .catch((e) => alert(e));
  };

  useEffect(() => {
    userFraudListHanlder();
  }, [searchType]);

  const fraudUpiHandler = async (e) => {
    e.preventDefault();
    var text1;
    var { value: text1 } = await Swal.fire({
      input: "textarea",
      inputLabel: "Upi Id Block",
      inputPlaceholder: "Type your reason here...",
      inputAttributes: {
        "aria-label": "Type your reason here",
      },
      showCancelButton: true,
    });
    if (text1) {
      axios
        .post(
          baseUrl + `user/upi`,
          { reason: text1, check: 1, upi_id: upi },
          { headers }
        )
        .then((res) => {
          userFraudListHanlder();
          alert(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Enter Reason",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const removeUpiHandler = (id) => {
    axios
      .post(baseUrl + `user/upi`, { id: id, check: 0 }, { headers })
      .then((res) => {
        userFraudListHanlder();
        alert(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const removeNameFraudHandler = async (id) => {
    var text1;
    var { value: text1 } = await Swal.fire({
      input: "textarea",
      inputLabel: "Name Fraud Remove",
      inputPlaceholder: "Type your reason here...",
      inputAttributes: {
        "aria-label": "Type your reason here",
      },
      showCancelButton: true,
    });
    if (text1) {
      axios
        .post(
          baseUrl + `user/name/${id}`,
          { reason: text1, check: 0 },
          { headers }
        )
        .then((res) => {
          userFraudListHanlder();
          alert(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Enter Reason",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  const removeFraudHandler = async (id) => {
    var text1;
    var { value: text1 } = await Swal.fire({
      input: "textarea",
      inputLabel: "Fraud User Remove",
      inputPlaceholder: "Type your reason here...",
      inputAttributes: {
        "aria-label": "Type your reason here",
      },
      showCancelButton: true,
    });
    if (text1) {
      axios
        .post(
          baseUrl + `user/fraud/${id}`,
          { reason: text1, check: 0 },
          { headers }
        )
        .then((res) => {
          userFraudListHanlder();
          alert(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Enter Reason",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <>
      <div>
        <h4 className="font-weight-bold my-3">All USER LIST</h4>
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card text-white">
              <div className="card-body text-light">
                <h4 className="card-title">Users List</h4>

                {/* searching */}
                <form onSubmit={fraudUpiHandler}>
                  <div className="row align-items-center">
                    <div className="col-md-4">
                      <input
                        type="text"
                        placeholder="Enter Upi Id..."
                        className="form-control col-sm-4 m-2"
                        onChange={(e) => setUpi(e.target.value)}
                        value={upi}
                      />
                    </div>
                    <div className="col-md-2">
                      <button className="btn btn-danger">Submit</button>
                    </div>
                  </div>
                </form>
                <div className="row">
                  <div className="col-md-3">
                    <select
                      className="form-control col-sm-3 m-2"
                      id="searchType"
                      name="searchtype"
                      onChange={(e) => setSearchType(e.target.value)}
                    >
                      <option value="Upifraud">Upifraud</option>
                      <option value="NameChange">Name Change</option>
                      <option value="fraud">Game fraud</option>
                    </select>
                  </div>
                  <div className="col-md-3">
                    <input
                      type="text"
                      placeholder="Search..."
                      className="form-control col-sm-4 m-2"
                      //   onChange={searchHandler}
                    />
                  </div>

                  <div className="col-md-1 ms-auto">
                    <select
                      className="form-control col-sm-1 m-1 bg-dark text-light"
                      id="pagelimit"
                      name="pagelimit"
                      onChange={setpageLimit}
                    >
                      <option value="10">Set limit</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                    </select>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table text-light alluser">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th> ID</th>
                        <th> Name</th>
                        <th> Upi Id</th>
                        <th> Reason</th>
                        <th> Admin Name</th>
                        <th> Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {user &&
                        user.map((user, key) => (
                          <tr key={user._id}>
                            <td>{key + 1}</td>
                            <td>{user._id}</td>
                            <td>
                              {user?.User_id ? (
                                <Link
                                  type="button"
                                  className={`btn btn-primary mx-1 ${
                                    parseFloat(user?.User_id?.checkFraud) ===
                                    parseFloat(1)
                                      ? "text-danger"
                                      : ""
                                  }`}
                                  to={`/user/view_user/${user?.User_id?._id}`}
                                >
                                  {user?.User_id?.Name}
                                </Link>
                              ) : (
                                "No User"
                              )}
                            </td>
                            <td>{user?.upiId}</td>
                            <td>{user?.txn_msg}</td>
                            <td>{user?.actionBy?.Name}</td>
                            <td>
                              {searchType === "Upifraud" ? (
                                <button
                                  type="button"
                                  className={`btn  mx-1 ${
                                    user.user_type == "Block"
                                      ? "btn-success"
                                      : "btn-danger block_btn"
                                  }`}
                                  onClick={() => removeUpiHandler(user._id)}
                                >
                                  Remove Upi
                                </button>
                              ) : searchType === "fraud" ? (
                                <button
                                  type="button"
                                  className={`btn  mx-1 ${
                                    user.user_type == "Block"
                                      ? "btn-success"
                                      : "btn-danger block_btn"
                                  }`}
                                  onClick={() =>
                                    removeFraudHandler(user?.User_id?._id)
                                  }
                                >
                                  Remove Fraud
                                </button>
                              ) : searchType === "NameChange" ? (
                                <button
                                  type="button"
                                  className={`btn  mx-1 ${
                                    user.user_type == "Block"
                                      ? "btn-success"
                                      : "btn-danger block_btn"
                                  }`}
                                  onClick={() =>
                                    removeNameFraudHandler(user?.User_id?._id)
                                  }
                                >
                                  Remove Name Fraud
                                </button>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

                <div className="mt-4">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={numberOfPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpiIdFraud;
