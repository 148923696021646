import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Gateway from "./Gateway";
import ReactSwitch from "react-switch";
import { useSelector } from "react-redux";

import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const Sitesettings = () => {
  const getOnUsersSiteSetting = useSelector(
    (state) => state.home.getOnUsersSiteSetting
  );
  const [checked, setChecked] = useState(true);
  const [checked1, setChecked1] = useState(true);
  const [checked2, setChecked2] = useState(true);
  const [checked3, setChecked3] = useState(true);
  const [checked0, setChecked0] = useState(true);
  const [checked4, setChecked4] = useState(true);
  const [checked5, setChecked5] = useState(true);
  const [checked6, setChecked6] = useState(true);

  const [checkedRoom, setCheckedRoom] = useState(true);
  const [checkedRoom1, setCheckedRoom1] = useState(true);
  const [checkedRoom2, setCheckedRoom2] = useState(true);
  const [checkedRoom3, setCheckedRoom3] = useState(true);
  const [checkedRoom4, setCheckedRoom4] = useState(true);

  const [checkedGame, setCheckedGame] = useState(true);

  const [checkedCommison, setCheckedCommision] = useState(true);

  const [checkedDeposit, setCheckedDeposit] = useState(true);

  const [checkedWithdrawal, setCheckedWithdrawal] = useState(true);

  const [checkedSupport, setCheckedSupport] = useState(true);

  const [checkedLogin, setCheckedLogin] = useState(true);
  const [checkedMaintenance, setCheckedMaintenance] = useState(true);

  // Api
  const [ludokingroomcodeURL, SetLudokingroomcodeURL] = useState("");
  const [ludokingPopularroomcodeURL, SetLudokingPopularroomcodeURL] =
    useState("");
  const [ludokingHostroomcodeURL, SetLudokingHostroomcodeURL] = useState("");
  const [ludoking1GroomcodeURL, SetLudoking1GroomcodeURL] = useState("");
  const [ludokingSnakeroomcodeURL, SetLudokingSnakeroomcodeURL] = useState("");
  const [depositlimitMin, setDepositMin] = useState("");
  const [depositlimitMax, setDepositMax] = useState("");
  const [gameTDS, setGameTDS] = useState("");
  const [referralCommission, setReferralCommission] = useState("");
  const [commissionRange1, setCommissionRange1] = useState("");
  const [commissionRange2, setCommissionRange2] = useState("");
  const [commissionRange3, setCommissionRange3] = useState("");
  const [withdrawalLimitMin, setWithdrawalMin] = useState("");
  const [withdrawalLimitMax, setWithdrawalMax] = useState("");
  const [autoWithdrawalLimitMax, setautoWithdrawalMax] = useState("");
  const [supportMail, setSupportMail] = useState("");
  const [supportInstagram, setSupportInstagram] = useState("");
  const [supportTelegram, setSupportTelegram] = useState("");
  const [supportWhatsapp, setSupportWhatsapp] = useState("");
  const [ludokingClassic, setLudokingClassicImage] = useState("");
  const [ludokingHost, setLudokingHostImage] = useState("");
  const [ludokingPopular, setLudokingPopularImage] = useState("");
  const [ludoking1G, setLudoking1GImage] = useState("");
  const [ludokingSnake, setLudokingSnakeImage] = useState("");
  const [mjludoClassic, setmjludoClassicImage] = useState("");
  const [mjludoPopular, setmjludoPopularImage] = useState("");
  const [mjludo1G, setmjludo1GImage] = useState("");

  const [WebTitle, setWebTitle] = useState("");
  const [WebsiteName, setWebName] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [CompanyAddress, setCompanyAddress] = useState("");
  const [CompanyMobile, setCompanyMobile] = useState("");
  const [Logo, setLogo] = useState("");
  const [SmallLogo, setSmallLogo] = useState("");
  const [site_message, setSite_message] = useState("");
  const [ludoClassicAutoMsg, setLudoClassicAutoMsg] = useState("");
  const [ludoClassicAutobattleMsg, setLudoClassicAutobattleMsg] = useState("");
  const [ludoClassicManualMsg, setLudoClassicManualMsg] = useState("");
  const [ludoClassicManualBattleMsg, setLudoClassicManualBattleMsg] =
    useState("");
  const [LandingImage1, setLandingImage1] = useState("");
  const [LandingImage2, setLandingImage2] = useState("");
  const [LandingImage3, setLandingImage3] = useState("");
  const [LandingImage4, setLandingImage4] = useState("");

  const [isLandingImage1, issetLandingImage1] = useState(true);
  const [isLandingImage2, issetLandingImage2] = useState(true);
  const [isLandingImage3, issetLandingImage3] = useState(true);
  const [isLandingImage4, issetLandingImage4] = useState(true);

  const [version, setVersion] = useState("");

  const [settingId, setSettingId] = useState("");

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  useEffect(() => {
    if (getOnUsersSiteSetting) {
      setSettingId(getOnUsersSiteSetting._id ? getOnUsersSiteSetting._id : "");
      setWebTitle(getOnUsersSiteSetting.WebTitle);
      setWebName(getOnUsersSiteSetting.WebsiteName);
      setCompanyName(getOnUsersSiteSetting.CompanyName);
      setCompanyAddress(getOnUsersSiteSetting.CompanyAddress);
      setCompanyMobile(getOnUsersSiteSetting.CompanyMobile);
      setSite_message(getOnUsersSiteSetting.site_message);
      setLudoClassicAutoMsg(getOnUsersSiteSetting.ludoClassicAutoMsg);
      setLudoClassicAutobattleMsg(
        getOnUsersSiteSetting.ludoClassicAutobattleMsg
      );
      setLudoClassicManualMsg(getOnUsersSiteSetting.ludoClassicManualMsg);
      setLudoClassicManualBattleMsg(
        getOnUsersSiteSetting.ludoClassicManualBattleMsg
      );
      setLogo(getOnUsersSiteSetting.Logo);
      setSmallLogo(getOnUsersSiteSetting.SmallLogo);
      setLandingImage1(getOnUsersSiteSetting.LandingImage1);
      setLandingImage2(getOnUsersSiteSetting.LandingImage2);
      setLandingImage3(getOnUsersSiteSetting.LandingImage3);
      setLandingImage4(getOnUsersSiteSetting.LandingImage4);
      issetLandingImage1(getOnUsersSiteSetting.isLandingImage1);
      issetLandingImage2(getOnUsersSiteSetting.isLandingImage2);
      issetLandingImage3(getOnUsersSiteSetting.isLandingImage3);
      issetLandingImage4(getOnUsersSiteSetting.isLandingImage4);

      setChecked(getOnUsersSiteSetting.isLudokingClassic);
      setChecked1(getOnUsersSiteSetting.isLudokingPopular);
      setChecked2(getOnUsersSiteSetting.isLudokingHost);
      setChecked3(getOnUsersSiteSetting.isLudoking1G);
      setChecked0(getOnUsersSiteSetting.isLudokingSnake);
      setChecked4(getOnUsersSiteSetting.ismjludoClassic);
      setChecked5(getOnUsersSiteSetting.ismjludoPopular);
      setChecked6(getOnUsersSiteSetting.ismjludo1G);

      setCheckedRoom(getOnUsersSiteSetting.isLudokingroomcode);
      setCheckedRoom1(getOnUsersSiteSetting.isLudokingPopularroomcode);
      setCheckedRoom2(getOnUsersSiteSetting.isLudokingHostroomcode);
      setCheckedRoom3(getOnUsersSiteSetting.isLudoking1Groomcode);
      setCheckedRoom4(getOnUsersSiteSetting.isLudokingSnakeroomcode);

      setCheckedCommision(getOnUsersSiteSetting.isReferral);

      setCheckedGame(getOnUsersSiteSetting.gameSearch);

      setCheckedDeposit(getOnUsersSiteSetting.isDeposit);

      setCheckedWithdrawal(getOnUsersSiteSetting.isWithdrawal);

      setCheckedSupport(getOnUsersSiteSetting.isChatSupport);

      setCheckedLogin(getOnUsersSiteSetting.userCanLogin);

      setCheckedMaintenance(getOnUsersSiteSetting.siteMaintenance);

      setVersion(getOnUsersSiteSetting.version);
      SetLudokingroomcodeURL(getOnUsersSiteSetting.ludokingroomcodeURL);
      SetLudokingPopularroomcodeURL(
        getOnUsersSiteSetting.ludokingPopularroomcodeURL
      );
      SetLudokingHostroomcodeURL(getOnUsersSiteSetting.ludokingHostroomcodeURL);
      SetLudoking1GroomcodeURL(getOnUsersSiteSetting.ludoking1GroomcodeURL);
      SetLudokingSnakeroomcodeURL(
        getOnUsersSiteSetting.ludokingSnakeroomcodeURL
      );
      setDepositMin(getOnUsersSiteSetting.depositlimitMin);
      setDepositMax(getOnUsersSiteSetting.depositlimitMax);
      setGameTDS(getOnUsersSiteSetting.gameTDS);
      setReferralCommission(getOnUsersSiteSetting.referralCommission);
      setCommissionRange1(getOnUsersSiteSetting.commissionRange1);
      setCommissionRange2(getOnUsersSiteSetting.commissionRange2);
      setCommissionRange3(getOnUsersSiteSetting.commissionRange3);
      setWithdrawalMin(getOnUsersSiteSetting.withdrawalLimitMin);
      setWithdrawalMax(getOnUsersSiteSetting.withdrawalLimitMax);
      setautoWithdrawalMax(getOnUsersSiteSetting.autoWithdrawalLimitMax);
      setSupportMail(getOnUsersSiteSetting.supportMail);
      setSupportInstagram(getOnUsersSiteSetting.supportInstagram);
      setSupportTelegram(getOnUsersSiteSetting.supportTelegram);
      setSupportWhatsapp(getOnUsersSiteSetting.whatsappSupport);
      // setLudokingClassicImage(res.data.ludokingClassic)
      // setLudokingHostImage(res.data.ludokingHost)
      // setLudokingPopularImage(res.data.ludokingPopular)
      // setLudoking1GImage(res.data.ludoking1G)
      // setLudokingSnakeImage(res.data.ludokingSnake)
      // setmjludoClassicImage(res.data.mjludoClassic)
      // setmjludoPopularImage(res.data.mjludoPopular)
      // setmjludo1GImage(res.data.mjludo1G)
    }
  }, [getOnUsersSiteSetting]);

  // const handleSubmit1 = (e) => {
  //   e.preventDefault();
  //   formData.append("isLudokingroomcode", checkedRoom);

  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("settingId", settingId);
    formData.append("WebTitle", WebTitle);
    formData.append("WebsiteName", WebsiteName);
    formData.append("CompanyName", CompanyName);
    formData.append("CompanyAddress", CompanyAddress);
    formData.append("CompanyMobile", CompanyMobile);
    formData.append("Logo", Logo);
    formData.append("SmallLogo", SmallLogo);
    formData.append("LandingImage1", LandingImage1);
    formData.append("LandingImage2", LandingImage2);
    formData.append("LandingImage3", LandingImage3);
    formData.append("LandingImage4", LandingImage4);
    formData.append("isLandingImage1", isLandingImage1);
    formData.append("isLandingImage2", isLandingImage2);
    formData.append("isLandingImage3", isLandingImage3);
    formData.append("isLandingImage4", isLandingImage4);
    formData.append("version", version);
    formData.append("site_message", site_message);
    formData.append("ludoClassicAutoMsg", ludoClassicAutoMsg);
    formData.append("ludoClassicAutobattleMsg", ludoClassicAutobattleMsg);
    formData.append("ludoClassicManualMsg", ludoClassicManualMsg);
    formData.append("ludoClassicManualBattleMsg", ludoClassicManualBattleMsg);

    formData.append("gameTDS", gameTDS);

    formData.append("referralCommission", referralCommission);
    formData.append("commissionRange1", commissionRange1);
    formData.append("commissionRange2", commissionRange2);
    formData.append("commissionRange3", commissionRange3);

    formData.append("depositlimitMin", depositlimitMin);
    formData.append("depositlimitMax", depositlimitMax);

    formData.append("withdrawalLimitMin", withdrawalLimitMin);
    formData.append("withdrawalLimitMax", withdrawalLimitMax);
    formData.append("autoWithdrawalLimitMax", autoWithdrawalLimitMax);

    formData.append("supportMail", supportMail);
    formData.append("supportInstagram", supportInstagram);
    formData.append("supportTelegram", supportTelegram);
    formData.append("whatsappSupport", supportWhatsapp);
    formData.append("isLudokingClassic", checked);
    formData.append("isLudokingPopular", checked1);
    formData.append("isLudokingHost", checked2);
    formData.append("isLudoking1G", checked3);
    formData.append("isLudokingSnake", checked0);
    formData.append("ismjludoClassic", checked4);
    formData.append("ismjludoPopular", checked5);
    formData.append("ismjludo1G", checked6);

    // formData.append("isLudokingroomcode", checkedRoom);
    formData.append("isLudokingPopularroomcode", checkedRoom1);
    formData.append("isLudokingHostroomcode", checkedRoom2);
    formData.append("isLudoking1Groomcode", checkedRoom3);
    formData.append("isLudokingSnakeroomcode", checkedRoom4);

    formData.append("gameSearch", checkedGame);

    formData.append("isReferral", checkedCommison);

    formData.append("isDeposit", checkedDeposit);

    formData.append("isWithdrawal", checkedWithdrawal);

    formData.append("isChatSupport", checkedSupport);

    formData.append("userCanLogin", checkedLogin);
    formData.append("siteMaintenance", checkedMaintenance);

    formData.append("ludokingroomcodeURL", ludokingroomcodeURL);
    formData.append("ludokingPopularroomcodeURL", ludokingPopularroomcodeURL);
    formData.append("ludokingHostroomcodeURL", ludokingHostroomcodeURL);
    formData.append("ludoking1GroomcodeURL", ludoking1GroomcodeURL);
    formData.append("ludokingSnakeroomcodeURL", ludokingSnakeroomcodeURL);

    if (ludokingClassic) {
      formData.append("ludokingClassic", ludokingClassic);
    }
    if (ludokingHost) {
      formData.append("ludokingHost", ludokingHost);
    }
    if (ludokingPopular) {
      formData.append("ludokingPopular", ludokingPopular);
    }

    if (ludoking1G) {
      formData.append("ludoking1G", ludoking1G);
    }
    if (ludokingSnake) {
      formData.append("ludokingSnake", ludokingSnake);
    }
    if (mjludoPopular) {
      formData.append("mjludoPopular", mjludoPopular);
    }
    if (mjludoClassic) {
      formData.append("mjludoClassic", mjludoClassic);
    }
    if (mjludo1G) {
      formData.append("mjludo1G", mjludo1G);
    }

    const access_token = localStorage.getItem("mjLudoTokenDash");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    const response = await axios.post(baseUrl + `settings`, formData, {
      headers,
    });
    if (response.data.status === "success") {
      alert("Settings submitted successfully");
    } else {
      alert("Settings Not Submitted");
    }
  };

  useEffect(() => {
    const Logo1 = document.getElementById("Logo");
    const Logo2 = document.getElementById("SmallLogo");
    const LandingImage1 = document.getElementById("LandingImage1");
    const LandingImage2 = document.getElementById("LandingImage2");
    const LandingImage3 = document.getElementById("LandingImage3");
    const LandingImage4 = document.getElementById("LandingImage4");

    Logo1.onchange = (e) => {
      const [file] = Logo1.files;
      setLogo(file);
    };
    Logo2.onchange = (e) => {
      const [file] = Logo2.files;
      setSmallLogo(file);
    };
    LandingImage1.onchange = (e) => {
      const [file] = LandingImage1.files;
      setLandingImage1(file);
    };
    LandingImage2.onchange = (e) => {
      const [file] = LandingImage2.files;
      setLandingImage2(file);
    };
    LandingImage3.onchange = (e) => {
      const [file] = LandingImage3.files;
      setLandingImage3(file);
    };
    LandingImage4.onchange = (e) => {
      const [file] = LandingImage4.files;
      setLandingImage4(file);
    };
  }, []);

  const handleChange = (val) => {
    setChecked(val);
  };
  const handleChange1 = (val) => {
    setChecked1(val);
  };
  const handleChange2 = (val) => {
    setChecked2(val);
  };
  const handleChange3 = (val) => {
    setChecked3(val);
  };
  const handleChange0 = (val) => {
    setChecked0(val);
  };
  const handleChange4 = (val) => {
    setChecked4(val);
  };
  const handleChange5 = (val) => {
    setChecked5(val);
  };
  const handleChange6 = (val) => {
    setChecked6(val);
  };

  const handleChangeRoom = (val) => {
    setCheckedRoom(val);
  };
  const handleChangeRoom1 = (val) => {
    setCheckedRoom1(val);
  };
  const handleChangeRoom2 = (val) => {
    setCheckedRoom2(val);
  };
  const handleChangeRoom3 = (val) => {
    setCheckedRoom3(val);
  };
  const handleChangeRoom4 = (val) => {
    setCheckedRoom4(val);
  };

  const handleChangeGame = (val) => {
    setCheckedGame(val);
  };

  const handleChangeCommison = (val) => {
    setCheckedCommision(val);
  };

  const handleChangeDeposit = (val) => {
    setCheckedDeposit(val);
  };

  const handleChangeWithdrawal = (val) => {
    setCheckedWithdrawal(val);
  };

  const handleChangeSupport = (val) => {
    setCheckedSupport(val);
  };

  const handleChangeLogin = (val) => {
    setCheckedLogin(val);
  };

  const handleChangeMaintenance = (val) => {
    setCheckedMaintenance(val);
  };

  return (
    <>
      <h3 className="text-uppercase font-weight-bold my-3 text-white">
        Website Settings
      </h3>

      <h4 className="text-uppercase font-weight-bold my-3 text-light">
        Game Settings
      </h4>
      <form
        onSubmit={handleSubmit}
        method="patch"
        encType="multipart/form-data"
      >
        <div className="form-row row">
          <div className="form-group col-md-6">
            <Card style={{ width: "29rem", marginTop: "10px" }}>
              <Card.Body>
                <Card.Title>Ludoking Classic</Card.Title>
                <Container>
                  <Row style={{ marginRight: "4.25rem" }}>
                    <Col sm={6}>Enable</Col>
                    <Col sm={2}>
                      {" "}
                      <ReactSwitch
                        checked={checked}
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Image</Col>
                    <Col sm={1}>
                      <input
                        type="file"
                        accept="image/*"
                        name="ludokingClassic"
                        // value={ludokingClassic}
                        onChange={(e) =>
                          setLudokingClassicImage(e.target.files[0])
                        }
                        style={{ width: "200px" }}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Room Code Enable</Col>
                    <Col sm={1}>
                      {" "}
                      <ReactSwitch
                        checked={checkedRoom}
                        onChange={handleChangeRoom}
                        style={{ display: "none" }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Room Code Url</Col>
                    <Col sm={1}>
                      {" "}
                      <input
                        type="url"
                        name="ludokingroomcodeURL"
                        value={ludokingroomcodeURL}
                        onChange={(e) => SetLudokingroomcodeURL(e.target.value)}
                        style={{
                          border: "1px solid black",
                          borderRadius: "3px",
                        }}
                        placeholder="Enter Url"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>
                      <button type="submit" className="btn btn-dark">
                        submit
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </div>

          <div className="form-group col-md-6">
            <Card style={{ width: "29rem" }}>
              <Card.Body>
                <Card.Title>Ludoking Popular</Card.Title>
                <Container>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Enable</Col>
                    <Col sm={2}>
                      {" "}
                      <ReactSwitch
                        checked={checked1}
                        onChange={handleChange1}
                        style={{ display: "none" }}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Image</Col>
                    <Col sm={1}>
                      <input
                        type="file"
                        accept="image/*"
                        name="ludokingPopular "
                        onChange={(e) =>
                          setLudokingPopularImage(e.target.files[0])
                        }
                        style={{ width: "200px" }}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Room Code Enable</Col>
                    <Col sm={1}>
                      {" "}
                      <ReactSwitch
                        checked={checkedRoom1}
                        onChange={handleChangeRoom1}
                        style={{ display: "none" }}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Room Code Url</Col>
                    <Col sm={1}>
                      {" "}
                      <input
                        type="url"
                        name="ludokingPopularroomcodeURL"
                        value={ludokingPopularroomcodeURL}
                        onChange={(e) =>
                          SetLudokingPopularroomcodeURL(e.target.value)
                        }
                        style={{
                          border: "1px solid black",
                          borderRadius: "3px",
                        }}
                        placeholder="Enter Url"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>
                      <button type="submit" className="btn btn-dark">
                        submit
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </div>
          <div className="form-group col-md-6">
            <Card style={{ width: "29rem" }}>
              <Card.Body>
                <Card.Title>Ludoking Host</Card.Title>
                <Container>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Enable</Col>
                    <Col sm={2}>
                      {" "}
                      <ReactSwitch
                        checked={checked2}
                        onChange={handleChange2}
                        style={{ display: "none" }}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Image</Col>
                    <Col sm={1}>
                      <input
                        type="file"
                        accept="image/*"
                        name="ludokingHost "
                        onChange={(e) =>
                          setLudokingHostImage(e.target.files[0])
                        }
                        style={{ width: "200px" }}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Room Code Enable</Col>
                    <Col sm={1}>
                      {" "}
                      <ReactSwitch
                        checked={checkedRoom3}
                        onChange={handleChangeRoom3}
                        style={{ display: "none" }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Room Code Url</Col>
                    <Col sm={1}>
                      {" "}
                      <input
                        type="url"
                        name="ludokingHostroomcodeURL"
                        value={ludokingHostroomcodeURL}
                        onChange={(e) =>
                          SetLudokingHostroomcodeURL(e.target.value)
                        }
                        style={{
                          border: "1px solid black",
                          borderRadius: "3px",
                        }}
                        placeholder="Enter Url"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>
                      <button type="submit" className="btn btn-dark">
                        submit
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </div>
          <div className="form-group col-md-6">
            <Card style={{ width: "29rem" }}>
              <Card.Body>
                <Card.Title>Ludo king1G</Card.Title>
                <Container>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Enable</Col>
                    <Col sm={2}>
                      {" "}
                      <ReactSwitch
                        checked={checked0}
                        onChange={handleChange0}
                        style={{ display: "none" }}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Image</Col>
                    <Col sm={1}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => setLudoking1GImage(e.target.files[0])}
                        style={{ width: "200px" }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Room Code Enable</Col>
                    <Col sm={1}>
                      {" "}
                      <ReactSwitch
                        checked={checkedRoom2}
                        onChange={handleChangeRoom2}
                        style={{ display: "none" }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Room Code Url</Col>
                    <Col sm={1}>
                      {" "}
                      <input
                        type="url"
                        name="ludoking1GroomcodeURL"
                        value={ludoking1GroomcodeURL}
                        onChange={(e) =>
                          SetLudoking1GroomcodeURL(e.target.value)
                        }
                        style={{
                          border: "1px solid black",
                          borderRadius: "3px",
                        }}
                        placeholder="Enter Url"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>
                      <button type="submit" className="btn btn-dark">
                        submit
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </div>
          <div className="form-group col-md-6">
            <Card style={{ width: "29rem" }}>
              <Card.Body>
                <Card.Title>Ludoking Snake</Card.Title>
                <Container>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Enable</Col>
                    <Col sm={2}>
                      {" "}
                      <ReactSwitch
                        checked={checked3}
                        onChange={handleChange3}
                        style={{ display: "none" }}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Image</Col>
                    <Col sm={1}>
                      <input
                        type="file"
                        accept="image/*"
                        name="ludokingSnake"
                        onChange={(e) =>
                          setLudokingSnakeImage(e.target.files[0])
                        }
                        style={{ width: "200px" }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Room Code Enable</Col>
                    <Col sm={1}>
                      {" "}
                      <ReactSwitch
                        checked={checkedRoom4}
                        onChange={handleChangeRoom4}
                        style={{ display: "none" }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Room Code Url</Col>
                    <Col sm={1}>
                      {" "}
                      <input
                        type="url"
                        name="ludokingSnakeroomcodeURL"
                        value={ludokingSnakeroomcodeURL}
                        onChange={(e) =>
                          SetLudokingSnakeroomcodeURL(e.target.value)
                        }
                        style={{
                          border: "1px solid black",
                          borderRadius: "3px",
                        }}
                        placeholder="Enter Url"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>
                      <button type="submit" className="btn btn-dark">
                        submit
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </div>
          <div className="form-group col-md-6">
            <Card style={{ width: "29rem" }}>
              <Card.Body>
                <Card.Title>mjludo Classic</Card.Title>
                <Container>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Enable</Col>
                    <Col sm={2}>
                      {" "}
                      <ReactSwitch
                        checked={checked4}
                        onChange={handleChange4}
                        style={{ display: "none" }}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Image</Col>
                    <Col sm={1}>
                      <input
                        type="file"
                        accept="image/*"
                        name="mjludoClassic"
                        onChange={(e) =>
                          setmjludoClassicImage(e.target.files[0])
                        }
                        style={{ width: "200px" }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>
                      <button type="submit" className="btn btn-dark">
                        submit
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </div>
          <div className="form-group col-md-6">
            <Card style={{ width: "29rem" }}>
              <Card.Body>
                <Card.Title>mjludo Popular</Card.Title>
                <Container>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Enable</Col>
                    <Col sm={2}>
                      {" "}
                      <ReactSwitch
                        checked={checked5}
                        onChange={handleChange5}
                        style={{ display: "none" }}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Image</Col>
                    <Col sm={1}>
                      <input
                        type="file"
                        accept="image/*"
                        name="mjludoPopular"
                        onChange={(e) =>
                          setmjludoPopularImage(e.target.files[0])
                        }
                        style={{ width: "200px" }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>
                      <button type="submit" className="btn btn-dark">
                        submit
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </div>
          <div className="form-group col-md-6">
            <Card style={{ width: "29rem" }}>
              <Card.Body>
                <Card.Title>Mj ludo1G</Card.Title>
                <Container>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Enable</Col>
                    <Col sm={2}>
                      {" "}
                      <ReactSwitch
                        checked={checked6}
                        onChange={handleChange6}
                        style={{ display: "none" }}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Image</Col>
                    <Col sm={1}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => setmjludo1GImage(e.target.files[0])}
                        style={{ width: "200px" }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>
                      <button type="submit" className="btn btn-dark">
                        submit
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </div>

          <div className="form-group col-md-6">
            <Card style={{ width: "29rem" }}>
              <Card.Body>
                <Card.Title>Other Game Setting</Card.Title>
                <Container>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Game Search</Col>
                    <Col sm={2}>
                      {" "}
                      <ReactSwitch
                        checked={checkedGame}
                        onChange={handleChangeGame}
                        style={{ display: "none" }}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Game TDS</Col>
                    <Col sm={1}>
                      <input
                        type="number"
                        value={gameTDS}
                        onChange={(e) => setGameTDS(e.target.value)}
                        style={{
                          border: "1px Solid Black",
                          borderRadius: "3px",
                        }}
                        placeholder="gameTDS"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>
                      <button type="submit" className="btn btn-dark">
                        submit
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </div>
        </div>
        <h4 className="text-uppercase font-weight-bold my-3 text-light">
          Referral/commison
        </h4>
        <div className="form-row row">
          <div className="form-group col-md-8">
            <Card style={{ width: "35rem" }}>
              <Card.Body>
                <Card.Title>Referral/commison</Card.Title>
                <Container>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Referral</Col>
                    <Col sm={2}>
                      {" "}
                      <ReactSwitch
                        checked={checkedCommison}
                        onChange={handleChangeCommison}
                        style={{ display: "none" }}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Referral Commission</Col>
                    <Col sm={1}>
                      <input
                        type="number"
                        value={referralCommission}
                        onChange={(e) => setReferralCommission(e.target.value)}
                        style={{
                          border: "1px Solid Black",
                          borderRadius: "3px",
                        }}
                        placeholder="Referral Commission"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Commission Range1</Col>
                    <Col sm={1}>
                      <input
                        type="number"
                        value={commissionRange1}
                        onChange={(e) => setCommissionRange1(e.target.value)}
                        style={{
                          border: "1px Solid Black",
                          borderRadius: "3px",
                        }}
                        placeholder="Commission Range1"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Commission Range2</Col>
                    <Col sm={1}>
                      <input
                        type="number"
                        value={commissionRange2}
                        onChange={(e) => setCommissionRange2(e.target.value)}
                        style={{
                          border: "1px Solid Black",
                          borderRadius: "3px",
                        }}
                        placeholder="Commission Range2"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Commission Range3</Col>
                    <Col sm={1}>
                      <input
                        type="number"
                        value={commissionRange3}
                        onChange={(e) => setCommissionRange3(e.target.value)}
                        style={{
                          border: "1px Solid Black",
                          borderRadius: "3px",
                        }}
                        placeholder="Commission Range3"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>
                      <button type="submit" className="btn btn-dark">
                        submit
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </div>
        </div>

        <h4 className="text-uppercase font-weight-bold my-3 text-light">
          deposit
        </h4>
        <div className="form-row row">
          <div className="form-group col-md-6">
            <Card style={{ width: "29rem" }}>
              <Card.Body>
                <Card.Title>Deposit</Card.Title>
                <Container>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Enable</Col>
                    <Col sm={2}>
                      {" "}
                      <ReactSwitch
                        checked={checkedDeposit}
                        onChange={handleChangeDeposit}
                        style={{ display: "none" }}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Deposit Limit Min</Col>
                    <Col sm={1}>
                      <input
                        type="number"
                        style={{
                          border: "1px Solid Black",
                          borderRadius: "3px",
                        }}
                        value={depositlimitMin}
                        onChange={(e) => setDepositMin(e.target.value)}
                        placeholder="Deposit Limit Min"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Deposit Limit Max</Col>
                    <Col sm={1}>
                      <input
                        type="number"
                        style={{
                          border: "1px Solid Black",
                          borderRadius: "3px",
                        }}
                        value={depositlimitMax}
                        onChange={(e) => setDepositMax(e.target.value)}
                        placeholder="Deposit Limit Max"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>
                      <button type="submit" className="btn btn-dark">
                        submit
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </div>
        </div>

        <h4 className="text-uppercase font-weight-bold my-3 text-light">
          Withdrawal
        </h4>
        <div className="form-row row">
          <div className="form-group col-md-8">
            <Card style={{ width: "35rem" }}>
              <Card.Body>
                <Card.Title>Withdrawal</Card.Title>
                <Container>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Enable</Col>
                    <Col sm={2}>
                      <ReactSwitch
                        checked={checkedWithdrawal}
                        onChange={handleChangeWithdrawal}
                        style={{ display: "none" }}
                      />
                    </Col>
                  </Row>

                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Withdrawal Limit Min</Col>
                    <Col sm={1}>
                      <input
                        type="number"
                        value={withdrawalLimitMin}
                        onChange={(e) => setWithdrawalMin(e.target.value)}
                        style={{
                          border: "1px Solid Black",
                          borderRadius: "3px",
                        }}
                        placeholder="Withdrawa Limit Min"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Withdrawal Limit Max</Col>
                    <Col sm={1}>
                      <input
                        type="number"
                        value={withdrawalLimitMax}
                        onChange={(e) => setWithdrawalMax(e.target.value)}
                        style={{
                          border: "1px Solid Black",
                          borderRadius: "3px",
                        }}
                        placeholder="Commission Range1"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}> Auto Withdrawal Limit</Col>
                    <Col sm={1}>
                      <input
                        type="number"
                        value={autoWithdrawalLimitMax}
                        onChange={(e) => setautoWithdrawalMax(e.target.value)}
                        style={{
                          border: "1px Solid Black",
                          borderRadius: "3px",
                        }}
                        placeholder="Auto Withdrawal Limit"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>
                      <button type="submit" className="btn btn-dark">
                        submit
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </div>
        </div>

        <h4 className="text-uppercase font-weight-bold my-3 text-light">
          Support
        </h4>
        <div className="form-row row">
          <div className="form-group col-md-6">
            <Card style={{ width: "29rem" }}>
              <Card.Body>
                <Card.Title>Support</Card.Title>
                <Container>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Support Mail</Col>
                    <Col sm={1}>
                      <input
                        type="Email"
                        value={supportMail}
                        onChange={(e) => setSupportMail(e.target.value)}
                        style={{
                          border: "1px Solid Black",
                          borderRadius: "3px",
                        }}
                        placeholder="Email"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Support Instagram</Col>
                    <Col sm={1}>
                      <input
                        type="url"
                        value={supportInstagram}
                        onChange={(e) => setSupportInstagram(e.target.value)}
                        style={{
                          border: "1px Solid Black",
                          borderRadius: "3px",
                        }}
                        placeholder="Enter Url"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Support Telegram</Col>
                    <Col sm={1}>
                      <input
                        type="url"
                        value={supportTelegram}
                        onChange={(e) => setSupportTelegram(e.target.value)}
                        style={{
                          border: "1px Solid Black",
                          borderRadius: "3px",
                        }}
                        placeholder="Enter Url"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Support Whats App</Col>
                    <Col sm={1}>
                      <input
                        type="url"
                        value={supportWhatsapp}
                        onChange={(e) => setSupportWhatsapp(e.target.value)}
                        style={{
                          border: "1px Solid Black",
                          borderRadius: "3px",
                        }}
                        placeholder="Enter Url"
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>Chat Support</Col>
                    <Col sm={2}>
                      {" "}
                      <ReactSwitch
                        checked={checkedSupport}
                        onChange={handleChangeSupport}
                        style={{ display: "none" }}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginRight: "4.25rem", marginTop: "10px" }}>
                    <Col sm={6}>
                      <button type="submit" className="btn btn-dark">
                        submit
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </div>
        </div>

        <h4 className="text-uppercase font-weight-bold my-3 text-light">
          Other
        </h4>

        <div className="form-row row">
          <div className="form-group col-md-6">
            <Row style={{ marginRight: "4.25rem" }}>
              <Col sm={6} style={{ color: "white" }}>
                User Login
              </Col>
              <Col sm={2}>
                <ReactSwitch
                  checked={checkedLogin}
                  onChange={handleChangeLogin}
                  style={{ display: "none" }}
                />
              </Col>
            </Row>
          </div>

          <div className="form-group col-md-6">
            <Row style={{ marginRight: "4.25rem" }}>
              <Col sm={6} style={{ color: "white" }}>
                Site Maintenance
              </Col>
              <Col sm={2}>
                {" "}
                <ReactSwitch
                  checked={checkedMaintenance}
                  onChange={handleChangeMaintenance}
                  style={{ display: "none" }}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="form-row row">
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Website Title</label>
            <input
              className="form-control"
              type="text"
              value={WebTitle}
              onChange={(e) => setWebTitle(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row row">
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Website Name</label>
            <input
              className="form-control"
              type="text"
              value={WebsiteName}
              onChange={(e) => setWebName(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row row">
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Commpany Name</label>
            <input
              className="form-control"
              type="text"
              value={CompanyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Commpany Address</label>
            <input
              className="form-control"
              type="text"
              value={CompanyAddress}
              onChange={(e) => setCompanyAddress(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Commpany Mobile</label>
            <input
              className="form-control"
              type="text"
              value={CompanyMobile}
              onChange={(e) => setCompanyMobile(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row row">
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Right Logo</label>
            <input className="form-control" type="file" name="Logo" id="Logo" />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Left Logo</label>
            <input
              className="form-control"
              type="file"
              name="SmallLogo"
              id="SmallLogo"
            />
          </div>
        </div>

        <div className="form-row row">
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Game image (1) </label>
            <input
              className="form-control"
              type="file"
              name="LandingImage1"
              id="LandingImage1"
            />
            <select
              className="form-control"
              name=""
              id=""
              value={isLandingImage1}
              onChange={(e) => issetLandingImage1(e.target.value)}
            >
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Game image (2)</label>
            <input
              className="form-control"
              type="file"
              name="LandingImage2"
              id="LandingImage2"
            />
            <select
              className="form-control"
              name=""
              id=""
              value={isLandingImage2}
              onChange={(e) => issetLandingImage2(e.target.value)}
            >
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Game image (3)</label>
            <input
              className="form-control"
              type="file"
              name="LandingImage3"
              id="LandingImage3"
            />
            <select
              className="form-control"
              name=""
              id=""
              value={isLandingImage3}
              onChange={(e) => issetLandingImage3(e.target.value)}
            >
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Game image (2)</label>
            <input
              className="form-control"
              type="file"
              name="LandingImage4"
              id="LandingImage4"
            />
            <select
              className="form-control"
              name=""
              id=""
              value={isLandingImage4}
              onChange={(e) => issetLandingImage4(e.target.value)}
            >
              <option value="true">on</option>
              <option value="false">off</option>
            </select>
          </div>
        </div>

        <div className="form-row row">
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">version</label>

            <input
              className="form-control"
              type="text"
              value={version}
              onChange={(e) => setVersion(e.target.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Landing Page Message</label>

            <input
              className="form-control"
              type="text"
              value={site_message}
              placeholder="Type a Message"
              onChange={(e) => setSite_message(e.target.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Ludo Classic Auto Msg</label>

            <input
              className="form-control"
              type="text"
              value={ludoClassicAutoMsg}
              placeholder="Type a Message"
              onChange={(e) => setLudoClassicAutoMsg(e.target.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Ludo Classic Auto Battle Msg</label>

            <input
              className="form-control"
              type="text"
              value={ludoClassicAutobattleMsg}
              placeholder="Type a Message"
              onChange={(e) => setLudoClassicAutobattleMsg(e.target.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Ludo Classic Manual Msg</label>

            <input
              className="form-control"
              type="text"
              value={ludoClassicManualMsg}
              placeholder="Type a Message"
              onChange={(e) => setLudoClassicManualMsg(e.target.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="WebsiteName">Ludo Classic Manual Battle Msg</label>

            <input
              className="form-control"
              type="text"
              value={ludoClassicManualBattleMsg}
              placeholder="Type a Message"
              onChange={(e) => setLudoClassicManualBattleMsg(e.target.value)}
            />
          </div>
        </div>

        <div className="form-row row">
          <div className="form-group col-md-4">
            <button type="submit" className="btn btn-dark">
              submit
            </button>
          </div>
        </div>
      </form>
      <Gateway />
    </>
  );
};
