import CryptoJS from "crypto-js";

export const validationFunction = (value) => {
  const bytes = CryptoJS.AES.decrypt(value, "mjLudoTokenExist");
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

export const secureTokenFunction = (value) => {
  const dataString = CryptoJS.AES.encrypt(
    JSON.stringify(value),
    "mjLudoTokenExist"
  ).toString();

  return dataString;
};
