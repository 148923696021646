import React, { useEffect, useState } from "react";
import { useLocation, withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
import Footer from "./shared/Footer";
import axios from "axios";
import admindata from "./components/admindata";
import { useSelector } from "react-redux";

const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
const nodeMode = process.env.NODE_ENV;
let baseUrl = beckendLiveApiUrl;

if (nodeMode === "development") {
  baseUrl = beckendLocalApiUrl;
}

function App() {
  const [permission, setPermission] = useState({});
  const [isFullPageLayout, setIsFullPageLayout] = useState(false);
  const location = useLocation();
  const getOnUsers = useSelector((state) => state.home.getOnUsers);

  useEffect(() => {
    if (isFullPageLayoutRoutes()) {
      setIsFullPageLayout(true);
      document
        .querySelector(".page-body-wrapper")
        .classList.add("full-page-wrapper");
    } else {
      setIsFullPageLayout(false);
      document
        .querySelector(".page-body-wrapper")
        .classList.remove("full-page-wrapper");
    }
    if (getOnUsers) {
      let userPermissions;
      if (getOnUsers.user_type === "Admin") {
        userPermissions = {
          usertype: "Admin",
          userAname: getOnUsers.Name,
          dashboard: true,
          earnings: true,
          allAdmins: true,
          newAdmin: true,
          allUsers: true,
          sitesettings: true,
          newUser: true,
          pendingKyc: true,
          completedKyc: true,
          rejectKyc: true,
          supportChat: true,
          pushNotification: true,
          updateLegalData: true,
          allChallenges: true,
          completedChallenges: true,
          conflictChallenges: true,
          cancelledChallenges: true,
          runningChallenges: true,
          newChallenge: true,
          penalty: true,
          bonus: true,
          depositHistory: true,
          bonusHistory: true,
          withdrawlHistory: true,
          allWithdrawlRequests: true,
          allDepositRequests: true,
          pages: true,
          bonusReport: true, //Added by Team
          penaltyReport: true, //Added by Team
          withdrawalReport: true, //Added by Team
          depositReport: true, //Added by Team
          updateLegalData: true, //Added by Team
        };
      } else if (getOnUsers.user_type === "Agent") {
        userPermissions = {
          usertype: "Agent",
          userAname: getOnUsers.Name,
          dashboard: getOnUsers.Permissions[0].Status,
          earnings: getOnUsers.Permissions[1].Status,
          allAdmins: getOnUsers.Permissions[2].Status,
          newAdmin: getOnUsers.Permissions[3].Status,
          allUsers: getOnUsers.Permissions[4].Status,
          newUser: getOnUsers.Permissions[5].Status,
          pendingKyc: getOnUsers.Permissions[6].Status,
          completedKyc: getOnUsers.Permissions[7].Status,
          rejectKyc: getOnUsers.Permissions[8].Status,
          allChallenges: getOnUsers.Permissions[9].Status,
          completedChallenges: getOnUsers.Permissions[10].Status,
          conflictChallenges: getOnUsers.Permissions[11].Status,
          cancelledChallenges: getOnUsers.Permissions[12].Status,
          runningChallenges: getOnUsers.Permissions[13].Status,
          newChallenge: getOnUsers.Permissions[14].Status,
          penalty: getOnUsers.Permissions[15].Status,
          bonus: getOnUsers.Permissions[16].Status,
          depositHistory: getOnUsers.Permissions[17].Status,
          withdrawlHistory: getOnUsers.Permissions[18].Status,
          allWithdrawlRequests: getOnUsers.Permissions[19].Status,
          allDepositRequests: getOnUsers.Permissions[20].Status,
          pages: getOnUsers.Permissions[21].Status,
          bonusHistory: getOnUsers.Permissions[22].Status,
          bonusReport: getOnUsers.Permissions[23].Status, //Added by Team
          withdrawalReport: getOnUsers.Permissions[24].Status, //Added by Team
          depositReport: getOnUsers.Permissions[25].Status, //Added by Team
          penaltyReport: getOnUsers.Permissions[26].Status, //Added by Team
          supportChat: getOnUsers.Permissions[27].Status, //Added by Team
          pushNotification: getOnUsers.Permissions[28].Status, //Added by Team
          updateLegalData: getOnUsers.Permissions[29].Status, //Added by Team
        };
      }
      setPermission(userPermissions);
      admindata.user = userPermissions;
      admindata.user["profile"] = getOnUsers;
    }
  }, [location, getOnUsers]);

  const isFullPageLayoutRoutes = () => {
    const fullPageLayoutRoutes = [
      "/user-pages/login-1",
      "/user-pages/login-2",
      "/user-pages/register-1",
      "/user-pages/register-2",
      "/user-pages/lockscreen",
      "/error-pages/error-404",
      "/error-pages/error-500",
      "/general-pages/landing-page",
      "/landing",
      "/login",
      "/register",
      "/adminlogin",
      "/home",
      "/profile",
      "/help",
      "/Deposit",
      "/",
      "/Homepage/pOPULAR",
      "/KYC/update-pan",
      "/KYC/update-aadhar",
      "/Games",
      "/Referral-history",
      `/landing/:id`,
      "/wallet",
      "/support",
      "/Withdrawopt",
      "/Addcase",
      "/Addfunds",
      "/Notification",
      "Chat",
      "/refer",
      "/transaction",
      "//transaction-history",
      "/web",
      "/return",
      "/redeem/refer",
      "/transaction-history",
    ];

    return fullPageLayoutRoutes.includes(location.pathname);
  };

  const navbarComponent = !isFullPageLayout ? (
    <Navbar usertype={permission.usertype} userAname={permission.userAname} />
  ) : null;

  const sidebarComponent = !isFullPageLayout ? (
    <Sidebar
      usertype={permission.usertype}
      dashboard={permission.dashboard}
      earnings={permission.earnings}
      allAdmins={permission.allAdmins}
      newAdmin={permission.newAdmin}
      sitesettings={permission.sitesettings} //Added by Team
      allUsers={permission.allUsers}
      newUser={permission.newUser}
      pendingKyc={permission.pendingKyc}
      completedKyc={permission.completedKyc}
      rejectKyc={permission.rejectKyc}
      supportChat={permission.supportChat}
      pushNotification={permission.pushNotification}
      allChallenges={permission.allChallenges}
      completedChallenges={permission.completedChallenges}
      conflictChallenges={permission.conflictChallenges}
      cancelledChallenges={permission.cancelledChallenges}
      runningChallenges={permission.runningChallenges}
      newChallenge={permission.newChallenge}
      penalty={permission.penalty}
      bonus={permission.bonus}
      depositHistory={permission.depositHistory}
      bonusHistory={permission.bonusHistory}
      withdrawlHistory={permission.withdrawlHistory}
      allWithdrawlRequests={permission.allWithdrawlRequests}
      allDepositRequests={permission.allDepositRequests}
      pages={permission.pages}
      bonusReport={permission.bonusReport} //Added by Team
      penaltyReport={permission.penaltyReport} //Added by Team
      withdrawalReport={permission.withdrawalReport} //Added by Team
      depositReport={permission.depositReport} //Added by Team
      LegalData={permission.LegalData} //Added by Team
    />
  ) : null;

  const footerComponent = !isFullPageLayout ? <Footer /> : null;

  return (
    <div className="container-scroller">
      {sidebarComponent}
      <div className="container-fluid page-body-wrapper">
        {navbarComponent}
        <div className="main-panel">
          <div className="content-wrapper">
            <AppRoutes />
          </div>
          {footerComponent}
        </div>
      </div>
    </div>
  );
}

export default withRouter(App);
