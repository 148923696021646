import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import "atropos/css";
import store from "./redux/store";
import { Provider } from "react-redux";
import PinMatch from "./app/PinMatch/PinMatch";

let findMyAccess = null;

const accessPath = localStorage.getItem("yesIAmFind");

const decode = (str) => {
  return decodeURIComponent(escape(atob(str)));
};
if (accessPath) {
  findMyAccess = decode(accessPath);
}

ReactDOM.render(
  <BrowserRouter>
    {findMyAccess === "IAmMjAccessUserFind" ? (
      <Provider store={store}>
        <App />
      </Provider>
    ) : (
      <PinMatch />
    )}
  </BrowserRouter>,
  document.getElementById("root")
);
